import urlEnum from "../../enumerations/urlEnum";
import styles from "./styles.module.scss";
const LinkItem = ({ item }) => {
  return (
    <a href={urlEnum[item.id]}>
      <div className={styles.linkItemContainer}>
        <img className={styles.listFrame} src={"/images/listFrame.svg"} alt="" />
        <div className={styles.tgButtonContainer}>
          <img src={"/images/tg.png"} alt={item.id} />
          <div className={styles.tgButton} href={urlEnum[item.id]}>
            <img src={"/images/click.png"} alt="click" />
            <span>Click to join</span>
          </div>
        </div>
        <div className={styles.divider} />
        <div className={styles.descContainer}>
          <span className={styles.desc}>AI Prediction</span>
          <span
            className={styles.title}
            style={{
              background: item.titleColor,
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            {item.title}
          </span>
          <span className={styles.desc2}>Stable trends will help you increase your profits.</span>
        </div>
        <div className={styles.imageContainer}>
          <img src={`/images/${item.id}.png`} alt={item.title} />
        </div>
      </div>
    </a>
  );
};

export default LinkItem;
